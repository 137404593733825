<template>
  <div class="imageCount">
    <span>{{ imageCount }}</span>
  </div>
</template>

<script>
export default {
  props: ['imageCount'],
}
</script>

<style scoped lang="scss">
.imageCount {
  position: absolute;
  top: 1.5%;
  left: 5%;
  height: 5vh;
  z-index: 6;
  text-align: center;

  span {
    position: relative;
    color: white;
    font-size: 13px;
    width: 100%;
  }
}
</style>
